import {IpRegionMap, ROUTER_STATUS_MAP} from "@/utils/constants.js";
import {h} from "vue";

export const HEADER_CELL_STYLE = {
    background: "rgba(228, 229, 241)",
    color: "rgba(102,102,102)",
}

export const ROUTER_TYPES = {
    // 'AX1800': {
    //     image: require('@/assets/routers/AX1800.png'),
    //     desc: 'WiFi 6 路由器\n' +
    //         '高通 4 核处理器\n' +
    //         '2.4GHz 速率 574 Mbps\n' +
    //         '5.2GHz 速率 1201 Mbps'
    // },
    'ZBT3000': {
        image: require('@/assets/routers/ZBT3000.png'),
        desc: 'WiFi 6 路由器\n' +
            '独立 WiFi 6 芯片加速\n' +
            '2.4GHz 速率 574 Mbps\n' +
            '5.8GHz 速率 2400 Mbps'
    }
}

// 路由器申请说明，可以写入 HTML
export const ROUTER_RENT_DECLARATION = `
<h3>术语解释</h3>

<div style="margin-left: 20px;">
    <strong>使用时间</strong>：单个路由器绑定有效线路的有效时间，绑定过期线路、不绑定线路不算使用时间<br>
    <strong>线路绑定</strong>：一个路由器同一时刻只能绑定一条线路
</div>
    
<h3>费用规则</h3>
<div style="margin-left: 30px;">
    <ol>
      <li>押金：<strong>400 元</strong></li>
      <li>使用时间满<strong>半年</strong>，寄回路由器，退全额押金</li>
      <li>使用时间不满<strong>半年</strong>，每<strong>月</strong>收<strong>折损费 50 元</strong>，如果路由器有明显损伤，或影响二次出租的，<strong>扣 50% 押金</strong></li>
    </ol>
</div>
`

const passwordFormatter = (row, column, cellValue, index) => {
    const columnProp = column.property
    const pwdMaskKey = columnProp + 'Mask'
    const pwdMaskShowKey = pwdMaskKey + 'Show'
    const finalPwd = row[pwdMaskShowKey] ? row[pwdMaskKey] : row[columnProp]

    let a = {};
    if (row.isShowBtn) {
        a = {
            class: "icon-edit-name iconfont icon-bianji",
            style: { "margin-left": "6px", cursor: "pointer" },
            on: {
                click: () => {
                    row[pwdMaskShowKey] = !row[pwdMaskShowKey]
                },
            },
        };
    }
    return h("div", [finalPwd, h("span", a)]);
}

const lineTooltip = (msg) => h(
  "el-tooltip",
  {
    attrs: {
      placement: "top-start",
      effect: "light",
    },
  },
  [
    h(
      "span",
      {
        slot: "content",
        style: {
          fontWeight: "bold",
          color: "red",
        },
      },
      msg
    ),
    h("i", {
      class: "el-icon-warning",
      style: { color: "red", 'margin-right': '3px' },
    }),
  ]
);

export const TABLE_COLUMNS = [
    // {
    //     width: "30",
    //     align: "left",
    //     selection: true,
    //     reserve: true,
    // },
    {
        prop: "RouterName",
        label: "名称",
        width: "120",
        align: "left",
        headerAlign: "left",
        selection: false,
        index: false,
        expand: false
    },
    {
        prop: "RouterId",
        label: "路由ID",
        width: "150",
        align: "left",
        headerAlign: "left",
        selection: false,
        index: false,
        expand: false,
        formatter: (row, column, cellValue, index) => {
            return cellValue.replace(/^router-/, '')
        },
    },
    // {
    //     prop: "RouterModel",
    //     label: "型号",
    //     width: "130",
    //     align: "left",
    //     headerAlign: "left",
    //     selection: false,
    //     index: false,
    //     expand: false,
    // },
    {
        prop: "MacAddr",
        label: "MAC地址",
        width: "150",
        align: "left",
        headerAlign: "left",
        selection: false,
        index: false,
        expand: false
    },

    {
        prop: "LineId",
        label: "线路ID",
        width: "125",
        align: "left",
        headerAlign: "left",
        selection: false,
        index: false,
        expand: false,
        formatter: (row, column, cellValue, index) => {
            const lineExpired = row.lineInfo.status ? row.lineInfo.status !== "STATUS_RUNNING" : false
            return h("span", [
              row.connected || !row.LineIp ? "" : lineTooltip("绑定的线路已断开！重连线路，或者重启路由器"),
              h('span', {style: {color: lineExpired ? 'red' : '#606266'}}, cellValue),
              lineExpired ? lineTooltip("绑定的线路已过期，请绑定其他线路或续费该线路") : '',
            ]);
        },
    },
    {
        prop: "LineRegion",
        label: "加速区域",
        width: "120",
        align: "left",
        headerAlign: "left",
        selection: false,
        index: false,
        expand: false,
        formatter: (row, column, cellValue, index) => {
            return IpRegionMap[cellValue] || cellValue;
        },
    },
    {
        prop: "LineIp",
        label: "线路IP",
        width: "140",
        align: "left",
        headerAlign: "left",
        selection: false,
        index: false,
        expand: false
    },

    // {
    //     prop: "DomesticWiFiSSID",
    //     label: "国内WiFi名称",
    //     width: "120",
    //     align: "left",
    //     headerAlign: "left",
    //     selection: false,
    //     index: false,
    //     expand: false,
    // },
    // {
    //     prop: "DomesticWiFiKey",
    //     label: "国内WiFi密码",
    //     width: "120",
    //     align: "left",
    //     headerAlign: "left",
    //     selection: false,
    //     index: false,
    //     expand: false,
    //     formatter: passwordFormatter
    // },
    // {
    //     prop: "FastWiFiSSID",
    //     label: "专线WiFi名称",
    //     width: "120",
    //     align: "left",
    //     headerAlign: "left",
    //     selection: false,
    //     index: false,
    //     expand: false,
    // },
    // {
    //     prop: "FastWiFiKey",
    //     label: "专线WiFi密码",
    //     width: "120",
    //     align: "left",
    //     headerAlign: "left",
    //     selection: false,
    //     index: false,
    //     expand: false,
    //     formatter: passwordFormatter
    // },

    // {
    //     prop: "Inuse",
    //     label: "状态",
    //     width: "80",
    //     align: "left",
    //     headerAlign: "left",
    //     selection: false,
    //     index: false,
    //     expand: false,
    //     formatter: (row, column, cellValue, index) => {
    //         return ROUTER_STATUS_MAP[cellValue - 0];
    //     },
    // }
]

export const toRouterVo = (dto = {}, i = 0) => {
    // 暂时只取 line 的这几个信息
    const lineInfo = dto.lineInfo || {
        ipRegion: '',
        id: '',
        ip: ''
    }

    let ret = {
        ...dto,
        lineInfo,
        LineRegion: lineInfo.ipRegion,
        LineId: lineInfo.id,
        LineIp: lineInfo.ip,
        Inuse: dto.inuse,
        MacAddr: dto.macAddr,
        RouterModel: dto.productId,
        RouterName: dto.name,
        RouterId: dto.routerId,
        Status: dto.status,
        // 路由器是否就绪
        Available: dto.status === 'STATUS_RUNNING',
        NotAvailable: dto.status !== 'STATUS_RUNNING',
        rowIndex: i,
        online: false,
        connected: false,
        onlineLoading: false,
    }

    const domesticWiFi = dto.internalWifi
    ret.DomesticWiFiSSID = domesticWiFi.ssid || ''
    ret.DomesticWiFiKey = domesticWiFi.key || ''
    // name sensitive。pattern:
    // <type>Mask
    // <type>MaskShow
    ret.DomesticWiFiKeyMask = ret.DomesticWiFiKey.replace(/.+?/g, '*')
    ret.DomesticWiFiKeyMaskShow = true
    ret.DomesticWiFi = {
        type: "Internal",
        typeLabel: "国内",
        SSID: domesticWiFi.ssid,
        Key: domesticWiFi.key
    }
    delete dto.internalWifi

    const fastWiFi = dto.lineWifi
    ret.FastWiFiSSID = fastWiFi.ssid || ''
    ret.FastWiFiKey = fastWiFi.key || ''
    // name sensitive
    ret.FastWiFiKeyMask = ret.FastWiFiKey.replace(/.+?/g, '*')
    ret.FastWiFiKeyMaskShow = true
    ret.FastWiFi = {
        type: "Line",
        typeLabel: "专线",
        SSID: fastWiFi.ssid,
        Key: fastWiFi.key
    }
    delete dto.lineWifi

    return ret;
}

export const centToYuan = (cent = 0) => cent / 100
